<template>
    <div class="page">
        <div class="auth-form__wrapper">
          <form class="auth-form" id="login-form">
            <div class="auth-form__logo-wrapper">
              <a :href="getLandingUrl" class="auth-form__logo">
                <img src="/img/logo.svg" alt="Logo">
              </a>
            </div>
            <h2 class="form-title">Sign Up</h2>
            <div class="details">
              <div class="login-with__social">
                <p class="login-with__social-text">Use daily base profile for fast communication</p>
                <div class="login-with__social-btns">
                  <a href="#" class="login-with__google btn wide btn-with__icon" @click="authProvider('google')">
                    <img class="img" src="/img/google.svg" />
                    Google
                  </a>
                </div>
                <p class="login-with__social-text">or sign up with E-mail</p>
              </div>
              <div class="input-group">
                <input class="form-control wide" type="text" v-model="name" placeholder="Your Name">
                <span class="invalid-feedback" v-if="nameError.length > 0">{{ nameError }}</span>
              </div>
              <div class="input-group">
                <input class="form-control wide" type="text" v-model="phone" placeholder="Your Phone">
                <span class="invalid-feedback" v-if="phoneError.length > 0">{{ phoneError }}</span>
              </div>
              <div class="input-group">
                <input class="form-control wide" type="text" v-model="email" placeholder="Your Email">
                <span class="invalid-feedback" v-if="emailError.length > 0">{{ emailError }}</span>
              </div>
              <div class="input-group password">
                <Password class="wide" v-model="password" placeholder="Password" :feedback="true" inputId="password" toggleMask />
                <span class="invalid-feedback" v-if="passwordError.length > 0">{{ passwordError }}</span>
              </div>
              <div class="input-group password">
                <Password class="wide" v-model="passwordConfirmation" placeholder="Confirm password" :feedback="false"
                  inputId="confirmPassword" toggleMask />
                <span class="invalid-feedback" v-if="passwordConfirmationError.length > 0">{{
                  passwordConfirmationError }}</span>
              </div>
              <div class="form__row input-group -checkbox" :class="{ '-not-valid': isNotAgree }">
                <input type="checkbox" name="agree" id="agree" v-model="agree">
                <label for="agree">I agree to the <a class="terms-link" :href="'https://n5bank.com/docs/terms.pdf'">terms and services</a></label>
              </div>
              <div class="auth-form__btn">
                <button class="btn wide submit" @click.prevent="signup">Sign Up</button>
              </div>
              <p class="auth-form__new-account">
                <span class="span">Do you already have an account?</span>
                <span>&nbsp;</span>
                <RouterLink class="auth-form__link" :to="{ name: 'Login' }">Sign in</RouterLink>
              </p>
            </div>
          </form>
        </div>
      </div>
  </template>
  
  <script>  
  import CircleLoad from '@/components/img/CircleLoad'

  export default {
    name: "Register",
    components: {
        CircleLoad
    },
    data() {
      return {
        load: false,
        email: '',
        phone: '',
        name: '',
        password: '',
        passwordConfirmation: '',
        nameError: '',
        phoneError: '',
        emailError: '',
        passwordError: '',
        passwordConfirmationError: '',
        agree: false,
        twoFactorOnLogin: false,
        partner_link: null,
        socialAuth: {
          providers: ['google'],
          code: null,
          provider: null,
          load: null,
        },
        backendUrl: process.env.VUE_APP_BACKEND_API_URL,
        isNotAgree: false,
        twoFactorOnLogin: false,
      }
    },
    created(){
      this.partner_link = this.$route.params.partner_link
    },
    computed: {
        getLandingUrl () {
        return process.env.VUE_APP_LANDING_URL
        }
    },
    watch: {
      agree: function (nv) {
        if (nv) this.isNotAgree = false
      },
    },
    methods: {
      resetErrors() {
        this.emailError = ''
        this.nameError = ''
        this.phoneError = ''
        this.passwordError = ''
        this.passwordConfirmationError = ''
        this.isNotAgree = false
      },
      async authProvider(provider) {
        this.load = true
        var self = this
        await this.$auth.authenticate(provider).then(response => {
            self.socialLogin(provider, response)
            }).catch(err => {
            console.log({err:err})
        })
        },
        async socialLogin(provider, response){
        const that = this
        await this.$http.post(process.env.VUE_APP_BACKEND_API_URL + 'auth/sociallogin/' + provider, {code: response.code}).then(response => {
            const { token, expiresIn } = response.data
            that.$store.dispatch('setToken', { token, expiresIn })
            that.$store.dispatch('setUser')
            that.$store.dispatch('setContent')
            that.get2faSettings()
        }).catch(err => {
            console.log({err:err})
        })
        this.load = false
      },
      async get2faSettings () {
        const that = this
        await axios.get(process.env.VUE_APP_BACKEND_API_URL + 'settings/customer-two-factor-on-login')
            .then(function ({ data }) {
            that.twoFactorOnLogin = (+data.customer_two_factor_on_login) ? true : false;
            const twoFactorOnLogin = that.twoFactorOnLogin
            that.$store.dispatch('setTwoFactorOnLogin', { twoFactorOnLogin });
            if (twoFactorOnLogin && data.code_is_not_empty) {
                that.$noty.success('Please, check your email - we send you two factor code.')
                that.$router.replace({ name: "LoginCode" })
            } else {
                that.$store.dispatch('removeTwoFactorOnLogin')
                that.$router.replace({ name: "YouOffers" })
            } 
            })
            .catch(function (error) {
            console.log(error);
            })
      },
      async signup() {
        this.resetErrors()
        
        if (!this.name) {
          this.nameError = 'Please enter a valid name!'
          return false
        }
        if (!this.email) {
          this.emailError = 'Please enter a valid email!'
          return false
        }
        if (!this.password || this.password.length < 7) {
          this.passwordError = 'The password must contain at least 8 characters!'
          return false
        }
        if (!this.passwordConfirmation || this.passwordConfirmation !== this.password) {
          this.passwordConfirmationError = 'Password mismatch!'
          return false
        }
        if (!this.agree) {
          this.isNotAgree = true
          return false
        }
  
        this.load = true
        await axios.post(process.env.VUE_APP_BACKEND_API_URL + 'auth/register', {
          email: this.email,
          name: this.name,
          phone: this.phone,
          password: this.password,
          password_confirmation: this.passwordConfirmation,
          referral_code: this.partner_link
        })
          .then(response => {
            console.log(response);
            if(response.data.token && response.data.expiresIn) {
                const { token, expiresIn } = response.data
                this.$store.dispatch('setToken', { token, expiresIn })
                this.$store.dispatch('setUser')
                this.$store.dispatch('setContent')
                this.get2faSettings() 
                
            } else {
              this.emailError = 'Error. Something went wrong'
            }
          })
          .catch(error => {
            if (error.response?.data?.errors) {
              for (let key in error.response.data.errors) {
                if (key === 'name' && error.response.data.errors[key][0]) {
                  this.nameError = error.response.data.errors[key][0]
                } else if (key === 'email' && error.response.data.errors[key][0]) {
                  this.emailError = error.response.data.errors[key][0]
                } else if (key === 'password' && error.response.data.errors[key][0]) {
                  this.passwordError = error.response.data.errors[key][0]
                }
              }
            }
          })
  
        this.load = false
      },
    }
  }
</script>
  
<style lang="scss" scoped>
  .page {
    position: relative;
    z-index: 1;
    &:before{
      content: "";
      z-index:-1;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: url('/img/nero.svg');
      background-repeat: no-repeat;
    }
    .auth-form__logo{
      max-width: 120px;
      &-wrapper{
        display: flex;
        width: 100%;
        justify-content: center;
      }
    }
    .auth-form__wrapper{
      width: 100%;
      height: 100%;
      max-width: 100%;
      max-height: 100%;
      display: flex;
      flex-direction: row;
      gap: 32px;
      justify-content: center;
      align-items: center;
    }
    .auth-form, .details{
      width: 480px;
      max-width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 32px
    }
    .details{
      gap: 24px;
    }
    .form-title {
      font-family: var(--title-bold-1-font-family);
      font-weight: var(--title-bold-1-font-weight);
      color: var(--on-surface);
      font-size: var(--title-bold-1-font-size);
      letter-spacing: var(--title-bold-1-letter-spacing);
      line-height: var(--title-bold-1-line-height);
      white-space: nowrap;
      font-style: var(--title-bold-1-font-style);
    }
    .login-with__social{
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 24px;
      width: 100%;
      &-btns{
        width: 100%;
      }
      &-text{
        font-family: var(--placeholder-field-font-family);
        font-weight: var(--placeholder-field-font-weight);
        color: var(--on-surface);
        font-size: var(--placeholder-field-font-size);
        letter-spacing: var(--placeholder-field-letter-spacing);
        line-height: var(--placeholder-field-line-height);
        white-space: nowrap;
        font-style: var(--placeholder-field-font-style);
      }
    }
    .input-group{
      width: 100%;
      input{
        display: flex;
        align-items: center;
        gap: 4px;
        padding: 16px 24px !important;
        position: relative;
        align-self: stretch;
        width: 100%;
        flex: 0 0 auto;
        border-radius: 5px;
        border: 1px solid;
        border-color: #ababb2;
        margin-bottom: 0 !important;
        &.wide{
            width: 100%;
        }
      }.wide{
        width: 100%;
      }
      &.password{
        position: relative;
        img{
          position: absolute;
          right: 25px;
          top: 17px;
        }
      }
    }
    .btn{
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 16px;
      padding: 16px 24px;
      position: relative;
      align-self: stretch;
      flex: 0 0 auto;
      border-radius: 5px;
      border: 1px solid;
      border-color: #09090b;
      color: #09090b;
      font-family: var(--for-button-font-family);
      font-weight: var(--for-button-font-weight);
      font-size: var(--for-button-font-size);
      letter-spacing: var(--for-button-letter-spacing);
      line-height: var(--for-button-line-height);
      white-space: nowrap;
      font-style: var(--for-button-font-style);
      &.wide{
        width: 100%;
      }
      &.submit{
        background-color: #1763fb;
        border-color: #1763fb;
        color: #fff
      }
    }
    .auth-form__forgot{
      display: flex;
      justify-content: end;
      width: 100%;
    }
    .auth-form__btn{
      display: flex;
      width: 100%;
    }
    .forgot-password{
      margin-left: auto;
      position: relative;
      width: fit-content;
      font-family: var(--placeholder-field-font-family);
      font-weight: var(--placeholder-field-font-weight);
      color: #3177ff;
      font-size: var(--placeholder-field-font-size);
      letter-spacing: var(--placeholder-field-letter-spacing);
      line-height: var(--placeholder-field-line-height);
      white-space: nowrap;
      font-style: var(--placeholder-field-font-style);
    }
    .invalid-feedback{
      color: red;
    }
  }
  .form-link{
    font-size: 18px;
    font-weight: bold;
    padding-top: 40px;
    padding-bottom: 30px;
}
.auth-form__new-account{
    font-family: var(--placeholder-field-font-family);
    font-weight: var(--placeholder-field-font-weight);
    color: var(--on-surface);
    font-size: var(--placeholder-field-font-size);
    letter-spacing: var(--placeholder-field-letter-spacing);
    line-height: var(--placeholder-field-line-height);
    white-space: nowrap;
    font-style: var(--placeholder-field-font-style);
}
.auth-form__link{
    color: #1763fb;
    margin-left: 4px;
    text-decoration: none;
  }
  .form__row{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    [type=checkbox]{
        width: auto !important;
        display: inline-flex !important
    }
    label{
        font-family: var(--placeholder-field-font-family);
        font-weight: var(--placeholder-field-font-weight);
        color: var(--on-surface);
        font-size: var(--placeholder-field-font-size);
        letter-spacing: var(--placeholder-field-letter-spacing);
        line-height: var(--placeholder-field-line-height);
        white-space: nowrap;
        font-style: var(--placeholder-field-font-style);
    }
  }
  .terms-link{
    color: #1763fb;
    text-decoration: none;
  }
</style>
  